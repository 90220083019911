<template>
  <div id="settings">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.ibProfile') }}</div>
        <div class="mt-5">
          <div class="item_header">{{ $t('settings.CustomerInfo') }}</div>

          <el-row>
            <el-col :xs="24" :sm="24" class="info-container">
              <div class="flex user_info">
                <div class="flex flex-column item">
                  <i>{{ $t('settings.name') }}</i>
                  <span>
                    {{ $store.state.common.userName }}
                  </span>
                </div>
                <div class="flex flex-column item">
                  <i>{{ $t('settings.email') }}</i>
                  <span>{{ $store.state.common.email }}</span>
                </div>
                <div class="flex flex-column item">
                  <i>{{ $t('settings.phone') }}</i>
                  <span>{{ $store.state.common.phone }}</span>
                </div>
              </div>
              <div class="user_info">
                <div class="flex flex-column item">
                  <i>{{ $t('settings.address') }}</i>
                  <span>
                    {{ $store.state.common.address }}
                  </span>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" class="flex regist-container flex-column bottom-rouned">
              <i>{{ $t('settings.reg') }}</i>
              <span>{{ $store.state.common.createTime | date('DD/MM/YYYY') }}</span></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';
</style>
